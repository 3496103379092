import React, { Fragment } from 'react'
import Banner from '../Banner'
import Helmet from 'react-helmet'

export default function Pagina (props) {
  const { pagina } = props
  // console.log(pagina.desktop.localFile.childImageSharp.gatsbyImageData)

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pt" />
        <title>{`${pagina.metaTitle} | Spani Atacadista `}</title>
        <meta name="title" content={`${pagina.metaTitle} | - Spani Atacadista`} />
        <meta name="description" content={pagina.metaDescription} />
        <meta name="keywords" content={pagina.tags.join(', ')} />
        <meta name="author" content="Spani" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={`${pagina.metaTitle} | - Spani Atacadista`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.spani.com.br${pagina.path}`} />
        <meta
          property="og:image"
          content={`https://www.spani.com.br${pagina.card.localFile.publicURL}`}
        />
        <meta property="og:description" content={pagina.metaDescription} />
      </Helmet>
      {pagina.possuiBanner &&
      <>
        <Banner
          desktop={pagina.desktop.localFile.childImageSharp.gatsbyImageData}
          mobile={pagina.mobile.localFile.childImageSharp.gatsbyImageData}
          alt="Banner"
        />
      </>
      }
    </Fragment>
  );
}
