import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout/index'
import Pagina from '../components/Pagina'

// styles
import './styles/o-spani.scss'

// Images
import targetIcon from '../images/grupo-zaragoza/icon-target.svg'
import spani from '../images/grupo-zaragoza/logo-s.png'

const QuemSomos = ({ data }) => {
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Institucional' },
      { path: '/grupo-zaragoza-spani/', name: 'Grupo Zaragoza' }
    ]
  }

  return (
    <Layout breadCrumb={breadC} >
      <Pagina pagina={data.pagina} />
      <section className="container p-0 bg-dark-blue">
        <div className='mb-5'>
          <GatsbyImage image={data.spani.childImageSharp.gatsbyImageData} alt="Somos o Spani Atacadista" />
          <p className='text-white p-5'>
            No ano de 2003, o Spani Atacadista iniciou suas operações com lojas nas cidades paulistas de
            Guaratinguetá e Mogi das Cruzes. Em 2010, além das novas lojas no Vale do Paraíba e São
            Paulo, também foram abertas 2 lojas na região Sul Fluminense. Em 2017, aconteceu a maior
            expansão do Grupo, com as inaugurações de 14 lojas Spani Atacadista. A expansão continuou
            ao longo dos anos pela Grande SP, Interior de SP, Vale do Paraíba, Região Bragantina, Região
            Sorocabana e no ano de 2023 conquistou a Região Metropolitana de Campinas com 2 novas
            lojas.<br /><br />
            Atualmente o Spani Atacadista é composto por 40 lojas espalhadas pelo Estado de São Paulo e
            Sul do Rio de Janeiro, além de um Centro de Distribuição com 130 mil mz, localizado em
            Taubaté/SP.<br /><br />
            Os resultados obtidos por todas as nossas unidades fazem do Spani Atacadista uma das
            referências do setor atacadista de autosserviço, sendo também a 12a maior empresa do
            segmento no país, segundo dados da ABAAS (Associação Brasileira dos Atacadistas de
            Autosserviço).<br /><br />
            Vinte anos após inaugurarmos nossa primeira loja, nós, do Spani Atacadista, estamos mais do
            que certos sobre o caminho que trilhamos. Temos muito orgulho do quanto crescemos até
            aqui e temos fôlego suficiente para continuarmos olhando para frente, acreditando em um
            futuro ainda mais próspero.<br /><br />
            Com mais de 5 mil colaboradores atuando em nossas unidades, pregamos um ambiente de
            trabalho ético e com oportunidades de crescimento, valorizando todos que se dedicam
            diariamente em busca da melhoria contínua, procurando alcançar a excelência em todos os
            níveis.
          </p>
          <GatsbyImage image={data.topicos.childImageSharp.gatsbyImageData} alt="Somos o Spani Atacadista" />
        </div>
      </section>
    </Layout>
  );
}

export default QuemSomos

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/grupo-zaragoza-spani/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
  }
  somosSpani: file(relativePath: {eq: "grupo-zaragoza/somos-spani.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  spani: file(relativePath: {eq: "grupo-zaragoza/banner.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  dna: file(relativePath: {eq: "grupo-zaragoza/dna.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  topicos: file(relativePath: {eq: "grupo-zaragoza/topicos.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  logoSpani: file(relativePath: {eq: "header/logo-spani.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
