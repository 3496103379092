import React, { Fragment } from 'react'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'

import PropTypes from 'prop-types'

import * as styles from './banner.module.scss'

export default function Banner (props) {
  const { desktop, mobile, alt } = props
  const sources = withArtDirection(getImage(desktop), [
    {
      media: '(max-width: 768px)',
      image: getImage(mobile)
    }
  ])
  return (
    <Fragment>
      <div>
        <figure>
          <GatsbyImage
            image={sources}
            loading="eager"
            fadeIn={false}
            alt={alt}
            className={styles.banner} />
        </figure>
      </div>
    </Fragment>
  );
}

Banner.propTypes = {
  desktop: PropTypes.object.isRequired,
  mobile: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired
}
